<template>
  <section id="contact" class="mt-5">
    <v-row align="center" justify="center">
      <v-col cols="12" xl="8" lg="9">
        <v-row align="start">
          <v-col cols="12" lg="6">
            <div class="mt-10">
              <div class="section-title mx-2 mb-10" style="font-size:28px">Get in Touch with Us!</div>
              <div class="d-flex align-center my-5" v-for="(item, i) in items" :key="i">
                <v-icon color="success" size="20">{{item.icon}}</v-icon>
                <div class="poppins ml-5" style="font-size: 18px">
                  {{item.text}}
                </div>
              </div>
            </div>
          </v-col>
          <v-col cols="12" lg="6">
            <v-card class="pa-7" color="primary-1" rounded="lg" max-width="450" style="z-index:1">
              <div class="">
                <div class="section-title white--text" style="font-size: 22px">
                  Send us a message
                </div>
                <div class="poppins white--text mt-2">
                  Please fill out this form and we'll get back to you within few hours!
                </div>
                <v-form class="mb-3 mt-7">
                  <v-text-field solo flat dense placeholder="Name"/>
                  <v-text-field solo dense placeholder="Email"/>
                  <v-select solo dense placeholder="Subject"/>
                  <v-textarea solo dense placeholder="Message"/>
                  <v-btn large block class="primary-1--text font-weight-bold">
                    Send
                  </v-btn>
                </v-form>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <div class="" style="margin-top:-450px;" data-aos="fade-up" data-aos-delay="100" data-aos-duration="500">
      <v-row align="center" justify="center">
        <v-col cols="12" xl="9">
          <v-row>
            <v-col cols="6" class="d-flex align-end justify-center">
              <div class="">
                <v-img max-height="100" max-width="100" :src="`${$assetStorage}/cat-ball.png`"/>
              </div>
            </v-col>
            <v-col cols="6" class="d-flex justify-end">
              <div class="">
                <v-img max-height="200" max-width="200" :src="`${$assetStorage}/cat-contacts.png`"/>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3861.178884990179!2d120.97184431535172!3d14.588880481273772!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3397cb102358efed%3A0x5f34ded7885c96d6!2sECJ%20Building!5e0!3m2!1sen!2sph!4v1620114834625!5m2!1sen!2sph"
        width="100%"
        height="410"
        style="border:0;"
        allowfullscreen="true"
        webkitallowfullscreen="true"
        mozallowfullscreen="true"
        class="mb-16"
        loading="lazy"
      ></iframe>
    </div>
    
    
  </section>
</template>

<script>
export default {
  data: () => ({
    items: [
      {
        icon: 'mdi-map-marker',
        text: '3F, ECJ Building, Real St.,Intramuros, Manila'
      },
      {
        icon: 'mdi-phone',
        text: '000-0000'
      },
      {
        icon: 'mdi-email',
        text: 'educat@pcitech.com.ph'
      },
    ]
  })
}
</script>